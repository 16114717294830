import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { onUserReceived, resetDepositOptions } from 'behavior';
import {
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CollapsibleCard from '../CollapsibleCard';
import OpenDepositSection from './OpenDepositSection';
import { AccountBalance } from '@material-ui/icons';
import BasicDepositInfo from './BasicDepositInfo';
import DetailsPopup from './DetailsPopup';

const DepositInfoCard = ({ deposits, currentWeek, resetDepositOptions }) => {
  const hasDeposits = !!deposits.length;
  const [openDetails, setOpenDetails] = useState(false);
  const selectedDeposit = useRef();

  useEffect(() => {
    resetDepositOptions();
  }, [deposits.length, resetDepositOptions]);

  return (
    <React.Fragment>
      <CollapsibleCard title="Your deposits">
        <CardContent>
          {!hasDeposits && (
            <Alert severity="info">
              <AlertTitle>No open deposits</AlertTitle>
              To start depositing press 'Open deposit' button below!
            </Alert>
          )}
          {hasDeposits && (
            <List>
              {deposits.map(deposit => (
                <ListItem key={deposit.id} button onClick={() => (selectedDeposit.current = deposit) & setOpenDetails(true)}>
                  <ListItemIcon>
                    <AccountBalance />
                  </ListItemIcon>
                  <ListItemText
                    primary={deposit.title}
                    secondary={<BasicDepositInfo {...deposit} currentWeek={currentWeek} />}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </CardContent>
        <OpenDepositSection />
      </CollapsibleCard>
      {selectedDeposit.current && <DetailsPopup isOpen={openDetails} onClose={() => setOpenDetails(false)} deposit={selectedDeposit.current} />}
    </React.Fragment>
  );
};

export default connect(
  ({ user: { deposits, currentWeek } }) => ({ deposits, currentWeek }),
  { onUserReceived, resetDepositOptions },
)(DepositInfoCard);