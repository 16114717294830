const USER_RECEIVED = 'USER_RECEIVED';
export const onUserReceived = userData => ({
  type: USER_RECEIVED,
  payload: userData,
});

const USER_EVENT_RECEIVED = 'USER_EVENT_RECEIVED';
export const receiveUserEvent = event => ({
  type: USER_EVENT_RECEIVED,
  payload: { event },
});

const USER_QUIZ_RECEIVED = 'USER_QUIZ_RECEIVED';
export const receiveUserQuiz = quiz => ({
  type: USER_QUIZ_RECEIVED,
  payload: { quiz },
});

const USER_SYNC_REQUESTED = 'USER_SYNC_REQUESTED';
export const syncUser = () => ({
  type: USER_SYNC_REQUESTED,
});

const USER_REPORT_STATE_CHANGED = 'USER_REPORT_STATE_CHANGED';
export const changeReportState = show => ({
  type: USER_REPORT_STATE_CHANGED,
  payload: { show },
});

const initialState = {
  sync: {},
  loaded: false,
  event: undefined,
  quiz: undefined,
  showReport: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_RECEIVED:
      return action.payload ? { ...state, ...action.payload, loaded: true } : { ...state, loaded: true };
    case USER_SYNC_REQUESTED:
      return { ...state, sync: {} };
    case USER_EVENT_RECEIVED:
      return { ...state, event: action.payload.event };
    case USER_QUIZ_RECEIVED:
      return { ...state, quiz: action.payload.quiz };
    case USER_REPORT_STATE_CHANGED:
      return { ...state, showReport: action.payload.show };
    default:
      return state;
  }
}