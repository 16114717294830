import React, { useState } from 'react';
import { connect } from 'react-redux';
import api from 'utils/api';
import { List, ListItem, ListItemText, ListItemIcon, Tooltip, CircularProgress, makeStyles } from '@material-ui/core';
import { NextWeek } from '@material-ui/icons';
import { onUserReceived, receiveUserEvent, receiveUserQuiz } from 'behavior';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles({
  list: {
    display: 'flex',
  },
  buttonContainer: {
    display: 'inline-block',
    width: '40%',
  },
  iconContainer: {
    display: 'inline-block',
    width: '20%',
  },
  listItemText: {
    textAlign: 'center',
    '& span': {
      fontSize: 15,
    },
  },
  progress: {
    color: green[500],
    position: 'absolute',
    left: 0,
    marginTop: -6,
    marginLeft: -8,
  },
});

const IncrementWeeksButtons = ({ accounts, defaultCurrency, onUserReceived, receiveUserEvent, receiveUserQuiz }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const negativeBalance = accounts.find(a => a.currency === defaultCurrency).value < 0;
  const isDisabled = loading || negativeBalance;

  return (
    <List className={classes.list}>
      <div className={classes.iconContainer}>
        <ListItem>
          <ListItemIcon style={{ position: 'relative' }}>
            <NextWeek />
            {loading && <CircularProgress size={40} className={classes.progress} />}
          </ListItemIcon>
        </ListItem>
      </div>
      <Tooltip arrow title={negativeBalance ? negativeBalanceText : 'Go to next week'}>
        <div className={classes.buttonContainer}>
          <ListItem button disabled={isDisabled} disableGutters
            onClick={() => {
              setLoading(true);
              api.nextWeek().then(({ userData, userEvent, userQuiz }) => {
                onUserReceived(userData);
                receiveUserEvent(userEvent);
                receiveUserQuiz(userQuiz);
                setTimeout(() => setLoading(false), 200);
              });
            }}
          >
            <ListItemText primary="Next week" className={classes.listItemText} />
          </ListItem>
        </div>
      </Tooltip>
      <Tooltip arrow title={negativeBalance ? negativeBalanceText : 'Go to next month'}>
        <div className={classes.buttonContainer}>
          <ListItem button disabled={isDisabled} disableGutters
            onClick={() => {
              setLoading(true);
              api.nextMonth().then(({ userData, userEvent, userQuiz }) => {
                onUserReceived(userData);
                receiveUserEvent(userEvent);
                receiveUserQuiz(userQuiz);
                setTimeout(() => setLoading(false), 200);
              });
            }}
          >
            <ListItemText primary="Next month" className={classes.listItemText} />
          </ListItem>
        </div>
      </Tooltip >
    </List>
  );
};

export default connect(
  ({ user: { accounts, defaultCurrency } }) => ({ accounts, defaultCurrency }),
  { onUserReceived, receiveUserEvent, receiveUserQuiz },
)(IncrementWeeksButtons);

const negativeBalanceText = `You are not able to move to next week while your default account is negative.
Consider positive balance on your default account or open "Credit"`;