const DEPOSIT_OPTIONS_RECEIVED = 'DEPOSIT_OPTIONS_RECEIVED';
export const depositOptionsReceived = depositOptions => ({
  type: DEPOSIT_OPTIONS_RECEIVED,
  payload: { depositOptions },
});

const DEPOSIT_OPTIONS_RESET = 'DEPOSIT_OPTIONS_RESET';
export const resetDepositOptions = () => ({
  type: DEPOSIT_OPTIONS_RESET,
});

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case DEPOSIT_OPTIONS_RECEIVED:
      return action.payload.depositOptions;
    case DEPOSIT_OPTIONS_RESET:
      return initialState;
    default:
      return state;
  }
}