import React from 'react';
import { Typography, withStyles, LinearProgress, lighten } from '@material-ui/core';
import { Amount } from 'components/primitives';

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten('#4caf50', 0.5),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#4caf50',
  },
})(LinearProgress);

export default function BasicDepositInfo({ currentWeek, percentage, balance, shouldCapitalize, durationInMonths, startWeek }) {
  return (
    <React.Fragment>
      Balance: <Typography variant="body2" component="span" color="textPrimary"><Amount {...balance} /></Typography><br />
      Percentage: <Typography variant="body2" component="span" color="textPrimary">{percentage + '%'}</Typography><br />
      {shouldCapitalize && (
        <>
          Capitalization: <Typography variant="body2" component="span" color="textPrimary">included</Typography><br />
        </>
      )}
      Progress:
      <BorderLinearProgress variant="determinate" color="secondary" value={(currentWeek - startWeek) / (durationInMonths * 4) * 100} />
    </React.Fragment>
  );
};