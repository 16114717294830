import React from 'react';
import { connect } from 'react-redux';
import { changeReportState } from 'behavior';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { TrendingUp } from '@material-ui/icons';

const ReportButton = ({ changeReportState }) => (
  <ListItem button onClick={changeReportState.bind(null, true)}>
    <ListItemIcon><TrendingUp /></ListItemIcon>
    <ListItemText primary="View report" />
  </ListItem>
);

export default connect(undefined, { changeReportState })(ReportButton);