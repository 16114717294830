import 'currency-flags/dist/currency-flags.css';
import React from 'react';
import { makeStyles, TableCell } from '@material-ui/core';

const useStyles = makeStyles({
  image: {
    verticalAlign: 'middle',
    marginRight: 10,
  },
});

const CurrencyTableCell = ({ currency }) => {
  const styles = useStyles();

  return (
    <TableCell style={{ whiteSpace: 'nowrap' }}>
      <span className={`${styles.image} currency-flag currency-flag-${currency.toLowerCase()}`}></span>
      <span>{currency}</span>
    </TableCell>
  );
};

export default CurrencyTableCell;