import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { changeReportState } from 'behavior';
import UserReportDialog from './UserReportDialog';

const UserReportHandler = ({ isReportAvailable, reportNotify, showReport, changeReportState }) => {
  useEffect(() => {
    reportNotify && changeReportState(true);
  }, [reportNotify]);

  return isReportAvailable ? <UserReportDialog open={showReport} onClose={changeReportState.bind(null, false)} /> : null;
};

export default connect(
  ({ user: { isReportAvailable, reportNotify, showReport } }) => ({ isReportAvailable, reportNotify, showReport }),
  { changeReportState },
)(UserReportHandler);