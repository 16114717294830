const ASSESSMENT_CONFIGURATIONS_RECEIVED = 'ASSESSMENT_CONFIGURATIONS/RECEIVED';
export const receiveAssessmentConfigurations = assessmentConfigurations => ({
  type: ASSESSMENT_CONFIGURATIONS_RECEIVED,
  payload: { assessmentConfigurations },
});

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case ASSESSMENT_CONFIGURATIONS_RECEIVED:
      return action.payload.assessmentConfigurations;
    default:
      return state;
  }
}