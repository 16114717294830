import React, { useState } from 'react';
import {
  AppBar, Toolbar, Typography,
  Drawer, IconButton, makeStyles,
  Hidden, Divider, List, CssBaseline, ListItem, ListItemText,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import IncrementWeeksButtons from './IncrementWeeksButtons';
import ReportButton from './ReportButton';
import ResetGameButton from './ResetGameButton';
import { connect } from 'react-redux';
import AccountInfo from './AccountInfo';
import AssessmentInfo from './AssessmentInfo';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
}));

const Navigation = ({ gameStarted, currentWeek, assessmentEnabled, isReportAvailable }) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  if (!gameStarted)
    return null;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <AccountInfo />
      <Divider />
      <IncrementWeeksButtons />
      <Divider />
      <List>
        <ResetGameButton />
      </List>
      <Divider />
      <List>
        {isReportAvailable && <ReportButton />}
        {!isReportAvailable && (
          <ListItem>
            <ListItemText>Report results in {60 - currentWeek} week(s)</ListItemText>
          </ListItem>
        )}
      </List>
      <Divider />
      {assessmentEnabled && (
        <>
          {currentWeek / 4 >= 6
            ? <AssessmentInfo />
            : (
              <ListItem>
                <ListItemText>Assessments in {24 - currentWeek} week(s)</ListItemText>
              </ListItem>
            )
          }
        </>
      )}
    </div>
  );

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap>
            Personal Finance - Week {currentWeek}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </>
  )
}

export default connect(
  ({ user: { gameStarted, currentWeek, assessmentEnabled, isReportAvailable } }) => (
    { gameStarted, currentWeek, assessmentEnabled, isReportAvailable }
  ),
)(Navigation);