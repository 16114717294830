import React from 'react';
import { Grid } from '@material-ui/core';
import SnackbarAlert from './SnackbarAlert';
import { UserEventHandler } from './events';
import { UserQuizHandler } from './quizzes';
import { UserReportHandler } from './report';
import CurrentAccountCard from './cards/currentAccount/CurrentAccountCard';
import SavingsCard from './cards/savings/SavingsCard';
import WeekUpdateCard from './cards/WeekUpdateCard';
import AnalyticsCard from './cards/AnalyticsCard';
import ExchangeRatesCard from './cards/ExchangeRatesCard';
import DepositInfoCard from './cards/deposit/DepositInfoCard';
import CreditInfoCard from './cards/credits/CreditInfoCard';
import TransactionsCard from './cards/transactions/TransactionsCard';
import NonFinancialInvestmentsCard from './cards/nonFinancialInvestments/NonFinancialInvestmentsCard';

const Game = () => (
  <>
    <Grid container spacing={3}>
      <Grid item lg={4} md={6} xs={12}>
        <CurrentAccountCard />
        <div style={{ margin: '1em 0' }}>
          <WeekUpdateCard />
        </div>
        <div style={{ margin: '1em 0' }}>
          <TransactionsCard />
        </div>
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <SavingsCard />
        <div style={{ margin: '1em 0' }}>
          <AnalyticsCard />
        </div>
        <div style={{ margin: '1em 0' }}>
          <ExchangeRatesCard />
        </div>
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <DepositInfoCard />
        <div style={{ margin: '1em 0' }}>
          <CreditInfoCard />
        </div>
        <div style={{ margin: '1em 0' }}>
          <NonFinancialInvestmentsCard />
        </div>
      </Grid>
    </Grid>
    <UserEventHandler />
    <UserQuizHandler />
    <UserReportHandler />
    <SnackbarAlert />
  </>
);

export default Game;