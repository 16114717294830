import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import api from 'utils/api';
import { onUserReceived, showSnackbarMessage } from 'behavior';
import {
  makeStyles,
  Button,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { CurrencyAutocomplete } from 'components/primitives';

const useStyles = makeStyles({
  formControl: {
    minWidth: 350,
  },
});

const OpenAccountDialog = ({ accounts, currencies, open, onClose, onUserReceived, showSnackbarMessage }) => {
  const styles = useStyles();
  const availableCurrencies = useMemo(() => {
    const usedCurrencies = accounts.map(a => a.currency);
    return currencies.filter(c => !usedCurrencies.includes(c.code));
  }, [accounts, currencies]);

  const formik = useFormik({
    initialValues: {
      currency: '',
    },
    validate: values => {
      const errors = {};

      if (!values.currency)
        errors.currency = 'Currency is required.';

      return errors;
    },
    onSubmit: ({ currency }) => api.openAccount(currency).then(data => {
      onUserReceived(data);
      showSnackbarMessage(<>You have opened a new account in {currency}.</>);
      onClose();
    }),
  });

  useEffect(() => { !open && setTimeout(formik.resetForm, 100); }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Open account</DialogTitle>
        <DialogContent>
          <DialogContentText>Select the currency in which you want to have a new account.</DialogContentText>
          <FormControl className={styles.formControl}>
            <CurrencyAutocomplete
              options={availableCurrencies}
              onChange={value => formik.setFieldValue('currency', value)}
              onBlur={() => formik.setFieldTouched('currency', true)}
              error={formik.touched.currency && !!formik.errors.currency}
              helperText={formik.touched.currency && formik.errors.currency}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button type="submit" color="primary">Open account</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(
  ({ user: { accounts }, currencies }) => ({ accounts, currencies }),
  { onUserReceived, showSnackbarMessage },
)(OpenAccountDialog);