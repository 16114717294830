const CURRENCIES_RECEIVED = 'CURRENCIES/RECEIVED';
export const receiveCurrencies = currencies => ({
  type: CURRENCIES_RECEIVED,
  payload: { currencies },
});

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case CURRENCIES_RECEIVED:
      return action.payload.currencies;
    default:
      return state;
  }
}