import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import api from 'utils/api';
import { CardContent, CardActions, Table, TableHead, TableBody, TableRow, TableCell, Button } from '@material-ui/core';
import CollapsibleCard from '../CollapsibleCard';
import { Amount, CurrencyTableCell } from 'components/primitives';
import TransactionsDialog from './TransactionsDialog';

const TransactionsCard = ({ accounts }) => {
  const [transactions, setTransactions] = useState();
  const [transactionsDialogOpen, setTransactionsDialogOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setTransactions(await api.getTransactions({ count: 5 }));
    })();
  }, [accounts]);

  return (
    <CollapsibleCard title="Transactions">
      {transactions && (
        <>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Currency</TableCell>
                  <TableCell>Operation</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((item, index) => (
                  <TableRow key={index}>
                    <CurrencyTableCell currency={item.amount.currency} />
                    <TableCell>{item.operationTitle}</TableCell>
                    <TableCell
                      align="right"
                      style={item.amount.value < 0 ? { whiteSpace: 'nowrap', color: 'red' } : { whiteSpace: 'nowrap', color: 'green' }}
                    >
                      <Amount {...item.amount} />
                    </TableCell>
                    <TableCell align="right" style={{ whiteSpace: 'nowrap' }}><Amount {...item.balance} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" size="small" onClick={setTransactionsDialogOpen.bind(null, true)}>View details</Button>
          </CardActions>
          <TransactionsDialog open={transactionsDialogOpen} onClose={setTransactionsDialogOpen.bind(null, false)} />
        </>
      )}
    </CollapsibleCard>
  );
};

export default connect(({ user: { accounts } }) => ({ accounts }))(TransactionsCard);