import React, { useState } from 'react';
import { makeStyles, Card, CardHeader, Collapse, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    '& .MuiCardContent-root, & .MuiList-root, & .MuiList-root div': {
      paddingTop: 0,
      marginTop: 0,
    },
  },
});

const CollapsibleCard = ({ title, children, ...props }) => {
  const styles = useStyles();
  const [expanded, setExpanded] = useState(true);

  return (
    <Card {...props}>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'h5', component: 'h2' }}
        onClick={setExpanded.bind(null, !expanded)}
        action={<IconButton>{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit className={styles.root}>
        {children}
      </Collapse>
    </Card>
  );
};

export default CollapsibleCard;