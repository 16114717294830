import React from 'react';
import {
  makeStyles, Typography, Button, Dialog, DialogActions,
  Card, CardMedia, CardContent,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Amount } from 'components/primitives';

const useStyles = makeStyles({
  root: {
    maxWidth: 350,
  },
  media: {
    height: 200,
  },
  warning: {
    marginTop: 20,
  },
});

const UserEventDialog = ({ event, open, onClose }) => {
  const styles = useStyles();

  let duration;
  if (event.duration.from < 100) {
    if (event.duration.from === event.duration.to) {
      duration = `${event.duration.from} week`;
      if (event.duration.from > 1)
        duration += 's';
    }
    else
      duration = `${event.duration.from} - ${event.duration.to} weeks`;
  }
  let spendingText;
  if ((event.duration.from === event.duration.to) === 1) {
    spendingText = <>You spent <Amount {...event.spending} /> on it.</>;
  } else {
    spendingText = <>Your first spending is <Amount {...event.spending} />.</>;
  }

  return (
    <Dialog open={open} onClose={onClose} disableBackdropClick>
      <Card className={styles.root}>
        {event.image && <CardMedia className={styles.media} image={event.image} />}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">{event.title}</Typography>
          <Typography variant="body2" color="textSecondary" component="p">{event.description}</Typography>
          <Alert severity="warning" className={styles.warning}>
            You incur additional costs for {duration || 'this'}, see them in the "Weekly update" section. {spendingText}
          </Alert>
        </CardContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>Close</Button>
        </DialogActions>
      </Card>
    </Dialog>
  );
};

export default UserEventDialog;