import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent,
  DialogContentText, TextField, DialogActions,
  Button, CardActions, FormControl,
  makeStyles, MenuItem,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { onUserReceived, showSnackbarMessage } from 'behavior';
import api from 'utils/api';
import { useFormik } from 'formik';
import { Amount } from 'components/primitives';
import getCurrencySymbol from 'currency-symbol-map';

const useStyles = makeStyles(theme => ({
  formControl: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
}));

const OpenCreditSection = ({ defaultCurrency, accounts, onUserReceived, showSnackbarMessage }) => {
  const styles = useStyles();

  const defaultAccountBalance = accounts.find(a => a.currency === defaultCurrency).value;
  const isEmergency = defaultAccountBalance < 0;
  const creditTitle = `${isEmergency ? 'Emergency ' : ''}Credit`;
  const [crediting, setCrediting] = useState(false);
  const percentage = isEmergency ? 29 : 21;

  useEffect(() => {
    if (defaultAccountBalance < 0)
      setCrediting(true);
  }, [defaultAccountBalance]);

  const formik = useFormik({
    initialValues: {
      amount: '',
      duration: 1,
    },
    validate: values => {
      const errors = {};

      if (!values.amount || values.amount < 0)
        errors.amount = 'Amount is required.';
      else if (Number.isNaN(Number(values.amount)))
        errors.amount = 'Amount must be a number.';
      else if (isEmergency && values.amount < -defaultAccountBalance)
        errors.amount = <>Emergency credit should make non-negative balance of <Amount value={defaultAccountBalance} currency={defaultCurrency} />.</>;

      return errors;
    },
    onSubmit: ({ amount, duration }) => {
      api.openCredit(creditTitle, parseFloat(amount), parseInt(duration), parseFloat(percentage)).then(data => {
        onUserReceived(data);
        showSnackbarMessage(<>Credit is taken with amount of <Amount value={amount} currency={defaultCurrency} /></>)
        closeDialog();
      })
    }
  });

  const closeDialog = () => {
    setCrediting(false);
    formik.resetForm();
  };

  return (
    <CardActions>
      <Button onClick={() => setCrediting(true)} variant="contained" color="primary" size="small">Take credit</Button>
      <Dialog open={crediting} onClose={closeDialog} disableBackdropClick>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>{creditTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {isEmergency && 'The balance on your current account is negative! In order to continue make sure your balance is non-negative or take emergency credit.'}
              Credit percentage is fixed and equals to {percentage}% per year and taken on annuity basis.
            </DialogContentText>
            <FormControl class={styles.formControl}>
              <TextField
                name="amount"
                label={`Amount (${defaultCurrency} ${getCurrencySymbol(defaultCurrency)})`}
                value={formik.values.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.amount && !!formik.errors.amount}
                helperText={formik.touched.amount && formik.errors.amount}
              />
            </FormControl>
            <FormControl className={styles.formControl}>
              <TextField
                name="duration"
                select
                label="Duration"
                value={formik.values.duration}
                onChange={formik.handleChange}
              >
                {[1, 3, 6, 12].map(d => (
                  <MenuItem key={d} value={d}>{d} month(s)</MenuItem>
                ))}
              </TextField>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="secondary">Cancel</Button>
            <Button type="submit" color="primary">Take credit</Button>
          </DialogActions>
        </form>
      </Dialog>
    </CardActions>
  )
}

export default connect(
  ({ user: { accounts, defaultCurrency } }) => ({ accounts, defaultCurrency }),
  { onUserReceived, showSnackbarMessage },
)(OpenCreditSection);