import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent,
  DialogContentText, TextField, DialogActions,
  Button, CardActions, FormControl,
  RadioGroup, FormControlLabel, Radio,
  Checkbox, FormHelperText, Paper,
  Tabs, Tab, Box
} from '@material-ui/core';
import { connect } from 'react-redux';
import { onUserReceived, showSnackbarMessage, depositOptionsReceived } from 'behavior';
import api from 'utils/api';
import { useFormik } from 'formik';
import { Amount } from 'components/primitives';
import getCurrencySymbol from 'currency-symbol-map';

const OpenDepositSection = ({ defaultCurrency, accounts, depositOptions, onUserReceived, showSnackbarMessage, depositOptionsReceived }) => {
  const [currency, setCurrency] = useState(defaultCurrency);
  const handleCurrencyChange = (_, newCurrency) => {
    setCurrency(newCurrency);
    formik.resetForm();
  }

  useEffect(() => {
    if (depositOptions.length)
      return;

    api.getDepositOptions().then(result => depositOptionsReceived(result));
  }, [depositOptions, depositOptionsReceived]);

  const optionsToShow = depositOptions.filter(o => o.currency === currency);
  const [depositing, setDepositing] = useState(false);

  const formik = useFormik({
    initialValues: {
      amount: '',
      depositOption: '0',
      shouldCapitalize: false,
      closable: false,
    },
    validate: values => {
      const errors = {};

      if (!values.amount)
        errors.amount = 'Amount is required.';

      else if (Number.isNaN(Number(values.amount)))
        errors.amount = 'Amount must be a number.';

      else {
        const accountAmount = accounts.find(a => a.currency === currency).value
        if (values.amount > accountAmount)
          errors.amount = <>You cannot open deposit with amount bigger than your current balance of <Amount value={accountAmount} currency={currency} />.</>;
      }

      return errors;
    },
    onSubmit: ({ amount, shouldCapitalize, closable, depositOption }) => {
      const deposit = optionsToShow[depositOption];

      api.deposit(deposit.id, closable, parseFloat(amount), shouldCapitalize).then(data => {
        onUserReceived(data);
        showSnackbarMessage(<>Deposit {deposit.title} is opened with balance of <Amount value={amount} currency={deposit.currency} /></>)
        closeDialog();
      })
    }
  });

  if (!depositOptions.length)
    return null;

  const closeDialog = () => {
    setDepositing(false);
    setCurrency(defaultCurrency);
    formik.resetForm();
  };

  return (
    <CardActions>
      <Button onClick={() => setDepositing(true)} variant="contained" color="primary" size="small">Open deposit</Button>
      <Dialog open={depositing} onClose={closeDialog}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Deposit</DialogTitle>
          <DialogContent>
            {accounts.length > 1 && (
              <Paper square style={{ marginBottom: 20 }}>
                <Tabs
                  centered
                  value={currency}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleCurrencyChange}
                >
                  {accounts.map(a => a.currency).map(c => (
                    <Tab key={c} value={c} label={`${c} ${getCurrencySymbol(c)}`} />
                  ))}
                </Tabs>
              </Paper>
            )}
            {!optionsToShow.length && 'No deposit options available for selected currency!'}
            {optionsToShow.length !== 0 && (
              <>
                <DialogContentText>Select options most comfortable for you.</DialogContentText>
                <Box>
                  <FormControl fullWidth component="fieldset">
                    <RadioGroup name="depositOption" value={formik.values.depositOption} onChange={formik.handleChange}>
                      {optionsToShow.map(({ id, title, percentage, closablePercentage }, index) => (
                        <FormControlLabel
                          key={id}
                          value={`${index}`} control={<Radio />}
                          label={title + ` - ${percentage}% (${closablePercentage}%*) per year, monthly interest.`}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
                <div>
                  <TextField
                    name="amount"
                    label={`Amount (${currency} ${getCurrencySymbol(currency)})`}
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.amount && !!formik.errors.amount}
                    helperText={formik.touched.amount && formik.errors.amount}
                  />
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="shouldCapitalize"
                      checked={formik.values.shouldCapitalize}
                      value={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  }
                  label="Include capitalization"
                />
                <FormHelperText>In case this option is turned on your interest will be added to the deposit balance instead of your main balance.</FormHelperText>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="closable"
                      checked={formik.values.closable}
                      value={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  }
                  label="*Flexible"
                />
                <FormHelperText>In case this option is selected deposit will be closable before due date. Interest percentage is lower in this case, which is mentioned in ().</FormHelperText>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="secondary">Cancel</Button>
            <Button type="submit" color="primary">Open deposit</Button>
          </DialogActions>
        </form>
      </Dialog>
    </CardActions>
  )
}

export default connect(
  ({ user: { defaultCurrency, accounts }, depositOptions }) => ({ defaultCurrency, accounts, depositOptions }),
  { onUserReceived, showSnackbarMessage, depositOptionsReceived },
)(OpenDepositSection);