export default {
  getLocationInfo: () => get('https://api.ipify.org').then(ip => get(`api/userLocationInfo/get/${ip}`)),

  getCurrencies: () => get('api/currencies/get'),

  getCountries: () => get('api/countries/get'),

  getAssessmentConfigurations: () => get('api/assessment/configurations'),

  startGame: input => post('api/user/start', input),

  getUserInfo: () => get('api/user/get'),

  getProgressInfo: () => get('api/user/progressInfo'),

  getTransactions: options => {
    const url = 'api/user/transactions';
    if (!options)
      return get(url);

    const { count, startWeek, endWeek } = options;
    return get(`${url}?count=${count}&startWeek=${startWeek}&endWeek=${endWeek}`);
  },

  getWeekInfo: () => get('api/user/weekInfo'),

  resetUser: () => post('api/user/reset'),

  nextWeek: () => post('api/user/nextWeek'),

  nextMonth: () => post('api/user/nextMonth'),

  openAccount: currency => post('api/user/openAccount', { currency }),

  currentAccountToSavings: (value, currency) => post('api/user/currentAccountToSavings', { value, currency }),

  savingsToCurrentAccount: (value, currency) => post('api/user/savingsToCurrentAccount', { value, currency }),

  getReports: () => get('api/user/reports'),

  getDepositOptions: () => get('api/deposit/getOptions'),

  deposit: (depositId, closable, amount, shouldCapitalize) => post('api/deposit/open', { depositId, closable, amount, shouldCapitalize }),

  addToDeposit: (depositId, amount) => post('api/deposit/addBalance', { depositId, amount }),

  closeDeposit: depositId => post('api/deposit/close', { depositId }),

  openCredit: (name, amount, duration, percentage) => post('api/credit/open', { name, amount, duration, percentage }),

  getExchangeRates: () => get('api/exchangerates/get'),

  convertAmountTo: (amount, fromCurrency, toCurrency, type) => post('api/exchangerates/convert', { amount, fromCurrency, toCurrency, type }),

  exchange: input => post('api/exchangerates/exchange', input),

  saveQuizResult: input => post('api/assessment/saveQuizResult', input),

  sendFeedback: feedback => post('api/user/feedback', { feedback }),

  getNonFinancialInvestments: () => get('api/nonFinancialInvestments/get'),

  buyNonFinancialInvestment: id => post('api/nonFinancialInvestments/buy', { id })
}

async function get(url) {
  const response = await fetch(url);
  return processResponse(response);
}

async function post(url, data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return processResponse(response);
}

function processResponse(response) {
  const contentType = response.headers.get('Content-Type');

  if (contentType && contentType.startsWith('application/json'))
    return response.json();

  return response.text();
}