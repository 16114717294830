import React from 'react';
import { connect } from 'react-redux';
import { hideSnackbarMessage } from 'behavior';
import { Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const SnackbarAlert = ({ show, message, variant, hideSnackbarMessage }) => (
  <Snackbar
    open={show}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    autoHideDuration={5000}
    onClose={hideSnackbarMessage}
  >
    <Alert severity={variant}>
      <Typography variant="body1">{message}</Typography>
    </Alert>
  </Snackbar>
);

export default connect(({ snackbar: { show, message, variant } }) => ({ show, message, variant }), { hideSnackbarMessage })(SnackbarAlert);