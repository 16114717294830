import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import api from 'utils/api';
import { onUserReceived, showSnackbarMessage } from 'behavior';
import {
  makeStyles, Button, FormControl, TextField, MenuItem,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@material-ui/core';
import { Amount } from 'components/primitives';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiSelect-root .currency-flag': {
      display: 'none'
    }
  },
  option: {
    '& > .currency-flag': {
      marginRight: 10
    }
  },
  formControl: {
    display: 'inline-block',
    '&:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  formGroup: {
    margin: '20px 0'
  }
}));

const ExchangeDialog = ({ defaultCurrency, accounts, open, onClose, onUserReceived, showSnackbarMessage }) => {
  const styles = useStyles();

  const formik = useFormik({
    initialValues: {
      from: { value: '', currency: defaultCurrency },
      to: { value: '', currency: accounts[1].currency },
    },
    validate: values => {
      const errors = {};

      if (!values.from.value || values.from.value < 0) {
        errors.from = {};
        errors.from.value = 'Amount is required.';
      } else if (Number.isNaN(Number(values.from.value))) {
        errors.from = {};
        errors.from.value = 'Amount must be a number.';
      }

      const accountAmount = accounts.find(a => a.currency === values.from.currency).value;
      if (values.from.value > +parseFloat(accountAmount).toFixed(2)) {
        errors.from = errors.from || {};
        errors.from.value = `You do not have enough money on your ${values.from.currency} account.`;
      }

      if (values.from.currency === values.to.currency) {
        errors.from = errors.from || {};
        errors.from.value = `You cannot buy the same currency.`;
      }

      if (!values.to.value || values.to.value < 0) {
        errors.to = {};
        errors.to.value = 'Amount is required.';
      } else if (Number.isNaN(Number(values.to.value))) {
        errors.to = {};
        errors.to.value = 'Amount must be a number.';
      }

      return errors;
    },
    onSubmit: ({ from, to }) => api.exchange(
      {
        from: { value: parseFloat(from.value), currency: from.currency },
        to: { value: parseFloat(to.value), currency: to.currency },
      }
    ).then(data => {
      onUserReceived(data);
      showSnackbarMessage(<>You spent <Amount {...from} /> to buy <Amount {...to} />.</>);
      onClose();
    }),
  });

  useEffect(() => { !open && setTimeout(formik.resetForm, 100); }, [open]);

  const getAnotherCurrency = currency => {
    for (const account of accounts) {
      if (account.currency !== currency)
        return account.currency;
    }
  }

  const handleFromValueChange = async e => {
    const value = e.target.value;
    const number = +parseFloat(value).toFixed(2);
    const isNan = Number.isNaN(Number(value));
    formik.setFieldValue('from.value', !value || isNan || value.endsWith('.') ? value : number, false);
    formik.setFieldTouched('from.value', true, false);
    if (isNan)
      return;

    if (number > 0) {
      const result = await api.convertAmountTo(parseFloat(e.target.value), formik.values.from.currency, formik.values.to.currency, 'buy');
      formik.setFieldValue('to.value', +parseFloat(result).toFixed(2), false);
      formik.setFieldTouched('to.value', true, true);
    } else {
      formik.setFieldValue('to.value', '', false);
      formik.setFieldTouched('to.value', true, true);
    }
  };

  const handleFromCurrencyChange = async e => {
    const currency = e.target.value;
    formik.setFieldValue('from.currency', currency, false);
    formik.setFieldTouched('from.currency', true, false);

    let toCurrency = formik.values.to.currency;
    if (currency === toCurrency) {
      toCurrency = getAnotherCurrency(currency);
      formik.setFieldValue('to.currency', toCurrency, false);
    }

    if (formik.values.from.value > 0) {
      const result = await api.convertAmountTo(parseFloat(formik.values.from.value), currency, toCurrency, 'buy');
      formik.setFieldValue('to.value', +parseFloat(result).toFixed(2), false);
      formik.setFieldTouched('to.value', true, true);
    } else if (formik.values.to.value) {
      formik.setFieldValue('to.value', '', false);
      formik.setFieldTouched('to.value', true, true);
    }
  };

  const handleToValueChange = async e => {
    const value = e.target.value;
    const number = +parseFloat(value).toFixed(2);
    const isNan = Number.isNaN(Number(value));
    formik.setFieldValue('to.value', !value || isNan || value.endsWith('.') ? value : number, false);
    formik.setFieldTouched('to.value', true, false);
    if (isNan)
      return;

    if (number > 0) {
      const result = await api.convertAmountTo(parseFloat(e.target.value), formik.values.to.currency, formik.values.from.currency, 'sell');
      formik.setFieldValue('from.value', +parseFloat(result).toFixed(2), false);
      formik.setFieldTouched('from.value', true, true);
    } else {
      formik.setFieldValue('from.value', '', false);
      formik.setFieldTouched('from.value', true, true);
    }
  };

  const handleToCurrencyChange = async e => {
    const currency = e.target.value;
    formik.setFieldValue('to.currency', currency, false);
    formik.setFieldTouched('to.currency', true, false);

    let fromCurrency = formik.values.from.currency;
    if (currency === fromCurrency) {
      fromCurrency = getAnotherCurrency(currency);
      formik.setFieldValue('from.currency', fromCurrency, false);
    }

    if (formik.values.to.value > 0) {
      const result = await api.convertAmountTo(parseFloat(formik.values.to.value), currency, fromCurrency, 'sell');
      formik.setFieldValue('from.value', +parseFloat(result).toFixed(2), false);
      formik.setFieldTouched('from.value', true, true);
    } else if (formik.values.from.value) {
      formik.setFieldValue('from.value', '', false);
      formik.setFieldTouched('from.value', true, true);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className={styles.root}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Exchange</DialogTitle>
        <DialogContent>
          <div>
            <FormControl className={styles.formControl}>
              <TextField
                name="from.value"
                label="From"
                value={formik.values.from.value}
                onChange={handleFromValueChange}
                error={formik.touched.from && formik.touched.from.value && formik.errors.from && !!formik.errors.from.value}
                helperText={formik.touched.from && formik.touched.from.value && formik.errors.from && formik.errors.from.value}
              />
            </FormControl>
            <FormControl className={styles.formControl}>
              <TextField
                name="from.currency"
                select
                label=" "
                value={formik.values.from.currency}
                onChange={handleFromCurrencyChange}
              >
                {accounts.map(a => (
                  <MenuItem key={a.currency} value={a.currency} className={styles.option}>
                    <span className={`currency-flag currency-flag-${a.currency.toLowerCase()}`} />
                    {a.currency}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
          <div className={styles.formGroup}>
            <FormControl className={styles.formControl}>
              <TextField
                name="to.value"
                label="To"
                value={formik.values.to.value}
                onChange={handleToValueChange}
                error={formik.touched.to && formik.touched.to.value && formik.errors.to && !!formik.errors.to.value}
                helperText={formik.touched.to && formik.touched.to.value && formik.errors.to && formik.errors.to.value}
              />
            </FormControl>
            <FormControl className={styles.formControl}>
              <TextField
                name="to.currency"
                select
                label=" "
                value={formik.values.to.currency}
                onChange={handleToCurrencyChange}
              >
                {accounts.map(a => (
                  <MenuItem key={a.currency} value={a.currency} className={styles.option}>
                    <span className={`currency-flag currency-flag-${a.currency.toLowerCase()}`} />
                    {a.currency}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button type="submit" color="primary">Exchange</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(
  ({ user: { defaultCurrency, accounts } }) => ({ defaultCurrency, accounts }),
  { onUserReceived, showSnackbarMessage },
)(ExchangeDialog);