import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import api from 'utils/api';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableHead, TableBody, TableRow, TableCell,
  Grid, FormControl, TextField
} from '@material-ui/core';
import { Amount, CurrencyTableCell } from 'components/primitives';

const TransactionsDialog = ({ currentWeek, open, onClose }) => {
  const [transactions, setTransactions] = useState();
  const [startWeek, setStartWeek] = useState();
  const [endWeek, setEndWeek] = useState();

  useEffect(() => {
    open && (async () => {
      const startWeek = currentWeek - 3 > 0 ? currentWeek - 3 : 1;
      const endWeek = currentWeek;
      setStartWeek(startWeek);
      setEndWeek(endWeek);
      setTransactions(await api.getTransactions({ startWeek, endWeek }));
    })();
  }, [open]);

  const handleStartWeekChange = async e => {
    const startWeek = e.target.value;
    setStartWeek(startWeek);
    setTransactions(await api.getTransactions({ startWeek, endWeek }))
  };

  const handleEndWeekChange = async e => {
    const endWeek = e.target.value;
    setEndWeek(endWeek);
    setTransactions(await api.getTransactions({ startWeek, endWeek }))
  };

  if (!transactions)
    return null;

  return (
    <Dialog open={open}>
      <DialogTitle>Transactions</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item>
            <FormControl>
              <TextField name="startWeek" label="Start week" value={startWeek} onChange={handleStartWeekChange} />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <TextField name="endWeek" label="End week" value={endWeek} onChange={handleEndWeekChange} />
            </FormControl>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Currency</TableCell>
              <TableCell>Operation</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((item, index) => (
              <TableRow key={index}>
                <CurrencyTableCell currency={item.amount.currency} />
                <TableCell>{item.operationTitle}</TableCell>
                <TableCell
                  align="right"
                  style={item.amount.value < 0 ? { whiteSpace: 'nowrap', color: 'red' } : { whiteSpace: 'nowrap', color: 'green' }}
                >
                  <Amount {...item.amount} />
                </TableCell>
                <TableCell align="right" style={{ whiteSpace: 'nowrap' }}><Amount {...item.balance} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(({ user: { currentWeek } }) => ({ currentWeek }))(TransactionsDialog);