import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  makeStyles, Typography, Button, Dialog, DialogActions,
  Card, CardContent, Stepper, Step, StepLabel, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText,
} from '@material-ui/core';
import api from 'utils/api';
import { onUserReceived } from 'behavior';
import { useEffect } from 'react';

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  green: {
    color: 'green',
  },
});

const UserQuizDialog = ({ quiz, open, onClose, onUserReceived }) => {
  const styles = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [stepsData, setStepsData] = useState(quiz.questions.map(question => ({ id: question.id, answers: [] })));
  const [helperText, setHelperText] = useState();
  const [isError, setIsError] = useState(false);

  useEffect(() => { setHelperText('') }, [activeStep]);

  const validate = input => {
    const correctAnswers = quiz.questions[activeStep].answers.filter(a => a.isCorrect).map(a => a.id).sort();
    const answers = input.find(i => i.id === quiz.questions[activeStep].id).answers.sort();
    if (JSON.stringify(correctAnswers) !== JSON.stringify(answers)) {
      setHelperText('Sorry, wrong answer!');
      setIsError(true);
      return false;
    }

    setHelperText('Yes, it is correct!');
    setIsError(false);
    return true;
  };

  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1);
  const handleNext = async () => {
    if (!validate(stepsData) && activeStep !== 1)
      return;

    if (activeStep + 1 < quiz.questions.length) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else {
      await api.saveQuizResult({ quizId: quiz.id, questions: stepsData }).then(data => {
        onUserReceived(data);
        onClose();
      });
    }
  };

  const handleRadioChange = e => {
    const questionId = quiz.questions[activeStep].id;
    const input = { id: questionId, answers: [e.target.value] };
    const questionsInput = stepsData.filter(i => i.id !== questionId);
    questionsInput.push(input);
    setStepsData(questionsInput);
    validate(questionsInput);
  };

  return (
    <Dialog open={open} onClose={onClose} disableBackdropClick>
      <Card className={styles.root}>
        <CardContent>
          {quiz.questions.length > 1 && (
            <Stepper activeStep={activeStep} alternativeLabel>
              {quiz.questions.map(question => (
                <Step key={question.id}>
                  <StepLabel />
                </Step>
              ))}
            </Stepper>
          )}
          <div>
            <Typography variant="h2" className={styles.title}>{quiz.questions[activeStep].title}</Typography>
            {quiz.questions[activeStep].type === 'Radios' ? (
              <FormControl component="fieldset" error={isError}>
                <RadioGroup value={stepsData.find(i => i.id === quiz.questions[activeStep].id).answers[0] || ''} onChange={handleRadioChange}>
                  {quiz.questions[activeStep].answers.map(answer => (
                    <FormControlLabel key={answer.id} value={answer.id} control={<Radio color="primary" />} label={answer.title} />
                  ))}
                </RadioGroup>
                <FormHelperText className={styles.green}>{helperText}</FormHelperText>
              </FormControl>
            ) : <></>}
          </div>
        </CardContent>
        <DialogActions>
          {quiz.questions.length > 1 && (
            <Button disabled={activeStep === 0} onClick={handleBack}>Back</Button>
          )}
          <Button color="primary" onClick={handleNext}>
            {activeStep === quiz.questions.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </DialogActions>
      </Card>
    </Dialog>
  );
};

export default connect(undefined, { onUserReceived })(UserQuizDialog);