import React from 'react';
import CollapsibleCard from '../CollapsibleCard';
import { CardContent, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { connect } from 'react-redux';
import { onUserReceived } from 'behavior';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CardMembership } from '@material-ui/icons';
import BasicCreditInfo from './BasicCreditInfo';
import OpenCreditSection from './OpenCreditSection';

const CreditInfoCard = ({ credits, currentWeek }) => {
  const hasCredits = !!credits.length;
  return (
    <CollapsibleCard title="Your credits">
      <CardContent>
        {!hasCredits && (
          <Alert severity="info">
            <AlertTitle>No open credits.</AlertTitle>
              You are doing well!
          </Alert>
        )}
        {hasCredits && (
          <List>
            {credits.map((credit, index) => (
              <ListItem key={credit.name + index} button>
                <ListItemIcon>
                  <CardMembership />
                </ListItemIcon>
                <ListItemText
                  primary={credit.name}
                  secondary={<BasicCreditInfo {...credit} currentWeek={currentWeek} />}
                />
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
      <OpenCreditSection />
    </CollapsibleCard>
  );
};

export default connect(({ user: { credits, currentWeek } }) => ({ credits, currentWeek }), { onUserReceived })(CreditInfoCard);