import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { receiveUserQuiz } from 'behavior';
import UserQuizDialog from './UserQuizDialog';

const UserQuizHandler = ({ quiz, receiveUserQuiz }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    quiz && setDialogOpen(true);
  }, [quiz]);

  const handleClose = useCallback(() => {
    receiveUserQuiz(null);
    setDialogOpen(false);
  }, [receiveUserQuiz]);

  return quiz ? <UserQuizDialog quiz={quiz} open={dialogOpen} onClose={handleClose} /> : null;
};

export default connect(({ user: { quiz } }) => ({ quiz }), { receiveUserQuiz })(UserQuizHandler);