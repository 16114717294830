const COUNTRIES_RECEIVED = 'COUNTRIES/RECEIVED';
export const receiveCountries = countries => ({
  type: COUNTRIES_RECEIVED,
  payload: { countries },
});

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case COUNTRIES_RECEIVED:
      return action.payload.countries;
    default:
      return state;
  }
}