import 'flag-icon-css/css/flag-icon.min.css';
import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > .flag-icon': {
      marginRight: 10,
    },
  },
});

const CountryAutocomplete = ({ options, label = 'Country', value, onChange, onBlur, ...props }) => {
  const styles = useStyles();

  return (
    <Autocomplete
      value={value}
      options={options}
      getOptionLabel={option => `${option.code} - ${option.name}`}
      classes={{ option: styles.option }}
      autoHighlight
      onChange={(_, country) => onChange(country ? country.code : null)}
      onBlur={onBlur}
      renderOption={country => (
        <>
          <span className={`flag-icon flag-icon-${country.code.toLowerCase()}`} />
          {country.code} - {country.name}
        </>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          autoComplete="new-password"
          {...props}
        />
      )}
    />
  );
};

export default CountryAutocomplete;