import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import api from 'utils/api';
import { onUserReceived, showSnackbarMessage } from 'behavior';
import {
  makeStyles, Typography, Button, Dialog, DialogActions, Card, CardMedia, CardContent
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Amount } from 'components/primitives';

const useStyles = makeStyles({
  root: {
    maxWidth: 350
  },
  media: {
    height: 200
  },
  alert: {
    marginTop: 20
  },
  infoItem: {
    marginTop: 10
  }
});

const NonFinancialInvestmentDialog = ({ item, open, onClose, accounts, onUserReceived, showSnackbarMessage }) => {
  const styles = useStyles();
  const accountValue = useMemo(() => accounts.find(({ currency }) => currency === item.price.currency)?.value, [item, accounts]);

  const handleBuy = useCallback(() => api.buyNonFinancialInvestment(item.id).then(data => {
    onUserReceived(data);
    showSnackbarMessage(<>You spent <Amount {...item.price} /> to buy '{item.title}'.</>);
    onClose();
  }), [item, onUserReceived, showSnackbarMessage, onClose]);

  return (
    <Dialog open={open} onClose={onClose} disableBackdropClick>
      <Card className={styles.root}>
        {item.image && <CardMedia className={styles.media} image={item.image} />}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">{item.title}</Typography>
          <Typography variant="body2" color="textSecondary" component="p">{item.description}</Typography>
          <Alert severity="info" className={styles.alert}>
            <div>You can buy it for <Amount {...item.price} />.</div>
            <div className={styles.infoItem}>
              This investment will be automatically sold after buying in {item.willBeSoldAfterBuyingInWeeks.from} - {item.willBeSoldAfterBuyingInWeeks.to} weeks.
            </div>
            <div className={styles.infoItem}>Your possible income will be from {item.possibleIncome.from * 100}% to {item.possibleIncome.to * 100}%.</div>
          </Alert>
          {accountValue === undefined && (
            <Alert severity="error" className={styles.alert}>
              You don't have current account in {item.price.currency}.
            </Alert>
          )}
          {accountValue < item.price.value && (
            <Alert severity="error" className={styles.alert}>
              You don't have enough money on your current account.
            </Alert>
          )}
        </CardContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>Close</Button>
          <Button
            color="primary"
            onClick={handleBuy}
            disabled={accountValue === undefined || accountValue < item.price.value}
          >
            Buy it
          </Button>
        </DialogActions>
      </Card>
    </Dialog>
  );
};

export default connect(({ user: { accounts } }) => ({ accounts }), { onUserReceived, showSnackbarMessage })(NonFinancialInvestmentDialog);