const SNACKBAR_MESSAGE_SHOWED = 'SNACKBAR_MESSAGE_SHOWED';
export const showSnackbarMessage = (message, variant = 'success') => ({
  type: SNACKBAR_MESSAGE_SHOWED,
  payload: { message, variant },
});

const SNACKBAR_MESSAGE_HIDDEN = 'SNACKBAR_MESSAGE_HIDDEN';
export const hideSnackbarMessage = () => ({
  type: SNACKBAR_MESSAGE_HIDDEN,
});

const initialState = {
  show: false,
  message: null,
  variant: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_MESSAGE_SHOWED:
      return { ...state, ...action.payload, show: true };
    case SNACKBAR_MESSAGE_HIDDEN:
      return { ...state, show: false };
    default:
      return state;
  }
}