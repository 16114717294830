import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import api from 'utils/api';
import { CardContent, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';
import CollapsibleCard from './CollapsibleCard';
import { Amount, CurrencyTableCell } from 'components/primitives';

const ExchangeRatesCard = ({ accounts }) => {
  const [exchangeRates, setExchangeRates] = useState();

  useEffect(() => {
    (async () => {
      setExchangeRates(await api.getExchangeRates());
    })();
  }, [accounts.length]);

  if (!exchangeRates)
    return null;

  return (
    <CollapsibleCard title="Exchange rates">
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Currency</TableCell>
              <TableCell align="right">Buy</TableCell>
              <TableCell align="right">Sell</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exchangeRates.map(rate => (
              <TableRow key={rate.currency}>
                <CurrencyTableCell currency={rate.currency} />
                <TableCell align="right"><Amount {...rate.buy} /></TableCell>
                <TableCell align="right"><Amount {...rate.sell} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </CollapsibleCard>
  );
};

export default connect(({ user: { accounts } }) => ({ accounts }))(ExchangeRatesCard);