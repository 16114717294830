import { combineReducers } from 'redux';
import userReducer from './userReducer';
import countriesReducer from './countriesReducer';
import currenciesReducer from './currenciesReducer';
import snackbarReducer from './snackbarReducer';
import depositReducer from './depositReducer';
import assessmentConfigurationsReducer from './assessmentConfigurationsReducer';

export const rootReducer = combineReducers({
  user: userReducer,
  countries: countriesReducer,
  currencies: currenciesReducer,
  snackbar: snackbarReducer,
  depositOptions: depositReducer,
  assessmentConfigurations: assessmentConfigurationsReducer,
});