import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Typography, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore, CheckCircle, Cancel } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  list: {
    maxHeight: 500,
    overflow: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(2),
    marginBottom: 10,
  },
  category: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: 16,
  },
  assessmentItem: {
    padding: 0,
    paddingRight: theme.spacing(2),
    '& span': {
      fontSize: 13,
    },
  },
  iconWrapper: {
    minWidth: 34,
  },
  green: {
    color: 'green',
  },
  red: {
    color: 'red',
  },
}));

const AssessmentInfo = ({ passedAssessmentIds, assessmentConfigurations }) => {
  const styles = useStyles();
  const [open, setOpen] = useState(true);

  const points = useMemo(() => {
    const result = {};
    for (const item of assessmentConfigurations) {
      result[item.category] = 0;
      for (const config of item.configurations)
        passedAssessmentIds.includes(config.id) && (result[item.category] += config.points);
    }
    return result;
  }, [passedAssessmentIds, assessmentConfigurations]);

  const getIcon = id => {
    if (passedAssessmentIds.includes(id))
      return <CheckCircle fontSize="small" className={styles.green} />;

    return <Cancel fontSize="small" className={styles.red} />
  }

  return (
    <List className={styles.list}>
      <ListItem button onClick={setOpen.bind(null, !open)}>
        <ListItemText primary="Assessment" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {assessmentConfigurations.map(i => (
          <div key={i.category} className={styles.nested}>
            <Typography variant="h4" component="div" color="textPrimary" className={styles.category}>
              {i.category} ({points[i.category]}%)
            </Typography>
            <List component="div" disablePadding>
              {i.configurations.map(config => (
                <ListItem key={config.id} className={styles.assessmentItem}>
                  <ListItemIcon className={styles.iconWrapper}>{getIcon(config.id)}</ListItemIcon>
                  <ListItemText primary={config.title} />
                </ListItem>
              ))}
            </List>
          </div>
        ))}
      </Collapse>
    </List>
  )
}

export default connect(
  ({ user: { passedAssessmentIds }, assessmentConfigurations }) => ({ passedAssessmentIds, assessmentConfigurations }),
)(AssessmentInfo);