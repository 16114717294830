import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import api from 'utils/api';
import { onUserReceived, showSnackbarMessage } from 'behavior';
import {
  makeStyles,
  Button,
  FormControl,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { Amount } from 'components/primitives';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiSelect-root .currency-flag': {
      display: 'none'
    }
  },
  option: {
    '& > .currency-flag': {
      marginRight: 10
    }
  },
  formControl: {
    display: 'inline-block',
    marginRight: theme.spacing(1)
  }
}));

const ToSavingsDialog = ({ defaultCurrency, accounts, open, onClose, onUserReceived, showSnackbarMessage }) => {
  const styles = useStyles();

  const formik = useFormik({
    initialValues: {
      amount: '',
      currency: defaultCurrency,
    },
    validate: values => {
      const errors = {};

      if (!values.amount || values.amount < 0)
        errors.amount = 'Amount is required.';

      else if (Number.isNaN(Number(values.amount)))
        errors.amount = 'Amount must be a number.';

      const accountAmount = accounts.find(a => a.currency === values.currency).value;
      if (values.amount > accountAmount)
        errors.amount = <>You cannot save more than <Amount value={accountAmount} currency={values.currency} />.</>;

      return errors;
    },
    onSubmit: ({ amount, currency }) => api.currentAccountToSavings(parseFloat(amount), currency).then(data => {
      onUserReceived(data);
      showSnackbarMessage(<>You saved <Amount value={amount} currency={currency} /> from your current account.</>);
      onClose();
    }),
  });

  useEffect(() => { !open && setTimeout(formik.resetForm, 100); }, [open]);

  return (
    <Dialog open={open} onClose={onClose} className={styles.root}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Save your money</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter amount you want to save.</DialogContentText>
          <FormControl className={styles.formControl}>
            <TextField
              name="amount"
              label="Amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.amount && !!formik.errors.amount}
              helperText={formik.touched.amount && formik.errors.amount}
            />
          </FormControl>
          {accounts.length > 1 && (
            <FormControl className={styles.formControl}>
              <TextField
                name="currency"
                select
                label=" "
                value={formik.values.currency}
                onChange={formik.handleChange}
              >
                {accounts.map(a => (
                  <MenuItem key={a.currency} value={a.currency} className={styles.option}>
                    <span className={`currency-flag currency-flag-${a.currency.toLowerCase()}`} />
                    {a.currency}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button type="submit" color="primary">Continue</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(
  ({ user: { defaultCurrency, accounts } }) => ({ defaultCurrency, accounts }),
  { onUserReceived, showSnackbarMessage },
)(ToSavingsDialog);