import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import api from 'utils/api';
import { onUserReceived, receiveCountries, receiveCurrencies, receiveAssessmentConfigurations } from 'behavior';
import Game from './Game';
import GameStart from './GameStart';

const Home = ({
  userLoaded,
  gameStarted,
  assessmentEnabled,
  sync,
  countries,
  currencies,
  assessmentConfigurations,
  onUserReceived,
  receiveCountries,
  receiveCurrencies,
  receiveAssessmentConfigurations
}) => {
  const [locationInfo, setLocationInfo] = useState();

  useEffect(() => {
    (async () => {
      onUserReceived(await api.getUserInfo());
    })();
  }, [sync]);

  useEffect(() => {
    (async () => {
      receiveCountries(await api.getCountries());
      receiveCurrencies(await api.getCurrencies());
      receiveAssessmentConfigurations(await api.getAssessmentConfigurations());
    })();
  }, []);

  useEffect(() => {
    if (userLoaded && !gameStarted)
      api.getLocationInfo().then(setLocationInfo);
  }, [userLoaded, gameStarted]);

  if (!userLoaded || !countries.length || !currencies.length || (!gameStarted && locationInfo == null))
    return null;

  if (gameStarted && assessmentEnabled && !assessmentConfigurations.length)
    return null;

  return gameStarted ? <Game /> : <GameStart locationInfo={locationInfo} />;
};

export default connect(
  ({
    user: { loaded, gameStarted, assessmentEnabled, sync },
    countries,
    currencies,
    assessmentConfigurations
  }) => ({
    userLoaded: loaded,
    gameStarted,
    assessmentEnabled,
    sync,
    countries,
    currencies,
    assessmentConfigurations
  }),
  { onUserReceived, receiveCountries, receiveCurrencies, receiveAssessmentConfigurations }
)(Home);