import React from 'react';
import { Typography, withStyles, LinearProgress, lighten } from '@material-ui/core';
import { Amount } from 'components/primitives';

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten('#ff6c5c', 0.5),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#ff6c5c',
  },
})(LinearProgress);

export default function BasicCreditInfo({ currentWeek, percentage, totalAmount, durationInMonths, startWeek }) {
  return (
    <React.Fragment>
      Total amount: <Typography variant="body2" component="span" color="textPrimary"><Amount {...totalAmount} /></Typography><br />
      Percentage: <Typography variant="body2" component="span" color="textPrimary">{percentage + '%'}</Typography><br />
      Total duration:  <Typography variant="body2" component="span" color="textPrimary">{durationInMonths} month(s)</Typography><br />
      Progress:
      <BorderLinearProgress variant="determinate" color="secondary" value={(currentWeek - startWeek) / (durationInMonths * 4) * 100} />
    </React.Fragment>
  );
};