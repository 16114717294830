import React, { useState } from 'react';
import { connect } from 'react-redux';
import api from 'utils/api';
import { syncUser } from 'behavior';
import { ListItem, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Update } from '@material-ui/icons';

const ResetGameButton = ({ syncUser }) => {
  const [confirm, setConfirm] = useState(false);
  return (
    <>
      <ListItem button onClick={() => setConfirm(true)}>
        <ListItemIcon><Update /></ListItemIcon>
        <ListItemText primary="Reset game" />
      </ListItem>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={confirm}
      >
        <DialogTitle id="confirmation-dialog-title">Are you sure you want to reset?</DialogTitle>
        <DialogContent dividers>
          Reseting the game your progress will be lost for you. You'll need to select start options again.
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setConfirm(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => api.resetUser().then(() => syncUser())} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(undefined, { syncUser })(ResetGameButton);