import React, { useState } from 'react';
import { List, ListItem, ListItemText, makeStyles, Collapse } from '@material-ui/core';
import { connect } from 'react-redux';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Amount } from 'components/primitives';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const AccountInfo = ({ salary, spending }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  return (
    <List>
      <ListItem button onClick={setOpen.bind(null, !open)}>
        <ListItemText primary="Selected conditions" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className={classes.nested}>
            <ListItemText primary="Salary" secondary={<Amount {...salary} pre="+" />} />
          </ListItem>
          <ListItem className={classes.nested}>
            <ListItemText primary="Spending per month" secondary={<Amount {...spending} pre="-" />} />
          </ListItem>
        </List>
      </Collapse>
    </List>
  )
}

export default connect(({ user: { salary, spending } }) => ({
  salary,
  spending,
}))(AccountInfo);