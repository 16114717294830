import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CardContent, CardActions, Table, TableBody, TableRow, TableCell, Button } from '@material-ui/core';
import CollapsibleCard from '../CollapsibleCard';
import { Amount, CurrencyTableCell } from 'components/primitives';
import ToCurrentAccountDialog from './ToCurrentAccountDialog';

const SavingsCard = ({ savings }) => {
  const [toCurrentAccountDialogOpen, setToCurrentAccountDialogOpen] = useState(false);

  return (
    <CollapsibleCard title="Savings">
      <CardContent>
        <Table>
          <TableBody>
            {savings.map(a => (
              <TableRow key={a.currency}>
                <CurrencyTableCell currency={a.currency} />
                <TableCell align="right"><Amount {...a} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions>
        <Button variant="contained" color="primary" size="small" onClick={setToCurrentAccountDialogOpen.bind(null, true)}>To current account</Button>
      </CardActions>
      <ToCurrentAccountDialog open={toCurrentAccountDialogOpen} onClose={setToCurrentAccountDialogOpen.bind(null, false)} />
    </CollapsibleCard>
  );
};

export default connect(({ user: { savings } }) => ({ savings }))(SavingsCard);