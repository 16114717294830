import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CardContent, CardActions, Table, TableBody, TableRow, TableCell, Button } from '@material-ui/core';
import CollapsibleCard from '../CollapsibleCard';
import { Amount, CurrencyTableCell } from 'components/primitives';
import OpenAccountDialog from './OpenAccountDialog';
import ToSavingsDialog from './ToSavingsDialog';
import ExchangeDialog from './ExchangeDialog';

const GeneralInfoCard = ({ accounts, currencies }) => {
  const [openAccountDialogOpen, setOpenAccountDialogOpen] = useState(false);
  const [toSavingsDialogOpen, setToSavingsDialogOpen] = useState(false);
  const [exchangeDialogOpen, setExchangeDialogOpen] = useState(false);

  return (
    <CollapsibleCard title="Current account">
      <CardContent>
        <Table>
          <TableBody>
            {accounts.map(a => (
              <TableRow key={a.currency}>
                <CurrencyTableCell currency={a.currency} />
                <TableCell align="right"><Amount {...a} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions>
        {accounts.length !== currencies.length && (
          <>
            <Button variant="contained" color="primary" size="small" onClick={setOpenAccountDialogOpen.bind(null, true)}>Open acccount</Button>
            <OpenAccountDialog open={openAccountDialogOpen} onClose={setOpenAccountDialogOpen.bind(null, false)} />
          </>
        )}
        <Button variant="contained" color="primary" size="small" onClick={setToSavingsDialogOpen.bind(null, true)}>To savings</Button>
        <ToSavingsDialog open={toSavingsDialogOpen} onClose={setToSavingsDialogOpen.bind(null, false)} />
        {accounts.length > 1 && (
          <>
            <Button variant="contained" color="primary" size="small" onClick={setExchangeDialogOpen.bind(null, true)}>Exchange</Button>
            <ExchangeDialog open={exchangeDialogOpen} onClose={setExchangeDialogOpen.bind(null, false)} />
          </>
        )}
      </CardActions>
    </CollapsibleCard>
  );
};

export default connect(({ user: { accounts }, currencies }) => ({ accounts, currencies }))(GeneralInfoCard);