import 'currency-flags/dist/currency-flags.min.css';
import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > .currency-flag': {
      marginRight: 10,
    },
  },
});

const CurrencyAutocomplete = ({ options, label = 'Currency', value, onChange, onBlur, ...props }) => {
  const styles = useStyles();

  return (
    <Autocomplete
      value={value}
      options={options}
      getOptionLabel={option => `${option.code} - ${option.name}`}
      classes={{ option: styles.option }}
      autoHighlight
      onChange={(_, currency) => onChange(currency ? currency.code : null)}
      onBlur={onBlur}
      renderOption={currency => (
        <>
          <span className={`currency-flag currency-flag-${currency.code.toLowerCase()}`} />
          {currency.code} - {currency.name}
        </>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          autoComplete="new-password"
          {...props}
        />
      )}
    />
  );
};

export default CurrencyAutocomplete;