import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { receiveUserEvent } from 'behavior';
import UserEventDialog from './UserEventDialog';

const UserEventHandler = ({ event, receiveUserEvent }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    event && setDialogOpen(true);
  }, [event]);

  const handleClose = useCallback(() => {
    receiveUserEvent(null);
    setDialogOpen(false);
  }, [receiveUserEvent]);

  return event ? <UserEventDialog event={event} open={dialogOpen} onClose={handleClose} /> : null;
};

export default connect(({ user: { event } }) => ({ event }), { receiveUserEvent })(UserEventHandler);