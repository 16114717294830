import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import api from 'utils/api';
import { onUserReceived } from 'behavior';
import {
  makeStyles, Typography, Grid, FormControl, FormControlLabel,
  TextField, Switch, MenuItem, Button, FormHelperText,
} from '@material-ui/core';
import { CurrencyAutocomplete, CountryAutocomplete } from 'components/primitives';
import Header from './Header';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: `${theme.spacing(1)}px 0`,
    minWidth: 350,
  },
  description: {
    marginTop: theme.spacing(1),
  },
  buttonContainer: {
    margin: `${theme.spacing(3)}px 0`,
  },
}));

const defaults = ['10000/6000', '15000/9000', '30000/15000', '100000/30000'];

const GameStart = ({ locationInfo, countries, currencies, onUserReceived }) => {
  const styles = useStyles();
  const [showSalarySpendingDropdown, toggleSalarySpendingDropdown] = useState();

  const { countryCode: country, currency } = locationInfo;

  const formik = useFormik({
    initialValues: {
      country,
      currency,
      salarySpending: '',
      customSalary: '',
      customSpending: '',
      assessmentEnabled: true,
    },
    validate: values => {
      const errors = {};

      if (!values.country)
        errors.country = 'Country is required.';

      if (!values.currency)
        errors.currency = 'Currency is required.';

      if (values.salarySpending !== 'custom')
        return errors;

      if (values.customSalary === '' || values.customSalary < 0)
        errors.customSalary = 'Salary is required.';

      else if (Number.isNaN(Number(values.customSalary)))
        errors.customSalary = 'Salary must be a number.';

      if (values.customSpending === '' || values.customSpending < 0)
        errors.customSpending = 'Spending is required.';

      else if (Number.isNaN(Number(values.customSpending)))
        errors.customSpending = 'Spending must be a number.';

      return errors;
    },
    onSubmit: ({ country, currency, salarySpending, customSalary, customSpending, assessmentEnabled }) => {
      const parts = salarySpending.split('/');
      const salary = parseFloat(salarySpending === 'custom' ? customSalary : parts[0]);
      const spending = parseFloat(salarySpending === 'custom' ? customSpending : parts[1]);

      api.startGame({ country, currency, salary, spending, assessmentEnabled }).then(data => onUserReceived(data));
    },
  });

  useEffect(() => {
    if (formik.values.currency === 'UAH') {
      toggleSalarySpendingDropdown(true);
      formik.setFieldValue('salarySpending', defaults[0]);
    } else {
      toggleSalarySpendingDropdown(false);
      formik.setFieldValue('salarySpending', 'custom');
    }
  }, [formik.values.currency]);

  return (
    <>
      <Header />
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="body1" className={styles.description}>
          To start simulation select your country, default currency and your month salary/spending.
        </Typography>
        <Grid container spacing={3}>
          <Grid item>
            <FormControl className={styles.formControl}>
              <CountryAutocomplete
                value={countries.find(c => c.code === formik.values.country)}
                options={countries}
                onChange={value => formik.setFieldValue('country', value)}
                onBlur={() => formik.setFieldTouched('country', true)}
                error={formik.touched.country && !!formik.errors.country}
                helperText={formik.touched.country && formik.errors.country}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item>
            <FormControl className={styles.formControl}>
              <CurrencyAutocomplete
                value={currencies.find(c => c.code === formik.values.currency)}
                options={currencies}
                onChange={value => formik.setFieldValue('currency', value)}
                onBlur={() => formik.setFieldTouched('currency', true)}
                error={formik.touched.currency && !!formik.errors.currency}
                helperText={formik.touched.currency && formik.errors.currency}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {showSalarySpendingDropdown && (
            <Grid item>
              <FormControl className={styles.formControl}>
                <TextField
                  name="salarySpending"
                  select
                  label="Salary/spending"
                  value={formik.values.salarySpending}
                  onChange={formik.handleChange}
                >
                  {defaults.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)}
                  <MenuItem value="custom">Other</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
          )}
          {formik.values.salarySpending === 'custom' && (
            <React.Fragment>
              <Grid item>
                <FormControl className={styles.formControl}>
                  <TextField
                    name="customSalary"
                    label="Salary"
                    value={formik.values.customSalary}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.customSalary && !!formik.errors.customSalary}
                    helperText={formik.touched.customSalary && formik.errors.customSalary}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={styles.formControl}>
                  <TextField
                    name="customSpending"
                    label="Spending"
                    value={formik.values.customSpending}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.customSpending && !!formik.errors.customSpending}
                    helperText={formik.touched.customSpending && formik.errors.customSpending}
                  />
                </FormControl>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        <Grid container spacing={3}>
          <Grid item>
            <FormControl className={styles.formControl}>
              <FormControlLabel
                control={
                  <Switch
                    name="assessmentEnabled"
                    checked={formik.values.assessmentEnabled}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Enable assessment"
              />
              <FormHelperText>
                *Enabling assessments will allow you to check the list of completed and uncompleted estimation tasks by different categories after 6 months of simulation.
                <br />
                After that you'll still be able to complete assessments.
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <div className={styles.buttonContainer}>
          <Button type="submit" variant="contained" color="primary">Start game</Button>
        </div>
      </form>
    </>
  );
};

export default connect(({ countries, currencies }) => ({ countries, currencies }), { onUserReceived })(GameStart);
