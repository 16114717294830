import React from 'react';
import { makeStyles, Dialog, DialogTitle, DialogContent, FormControl, TextField, DialogActions, Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { onUserReceived, showSnackbarMessage, resetDepositOptions } from 'behavior';
import api from 'utils/api';
import { useFormik } from 'formik';
import { Amount } from 'components/primitives';
import getCurrencySymbol from 'currency-symbol-map';
import BasicDepositInfo from './BasicDepositInfo';

const useStyles = makeStyles({
  formControl: {
    marginTop: 20,
    minWidth: 350,
  },
});

const DetailsPopup = ({
  accountBalance,
  isOpen,
  onClose,
  deposit,
  currentWeek,
  onUserReceived,
  showSnackbarMessage,
  resetDepositOptions,
}) => {
  const styles = useStyles();
  const { id: depositId, title, balance: { value, currency }, closable } = deposit;

  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validate: values => {
      const errors = {};

      if (!values.amount || values.amount < 0)
        errors.amount = 'Amount is required.';

      else if (Number.isNaN(Number(values.amount)))
        errors.amount = 'Amount must be a number.';

      else if (values.amount > accountBalance)
        errors.amount = <>You cannot open deposit with amount bigger than your current balance of <Amount value={accountBalance} currency={currency} />.</>;

      return errors;
    },
    onSubmit: ({ amount }) => api.addToDeposit(depositId, parseFloat(amount)).then(data => {
      onUserReceived(data);
      showSnackbarMessage(<><Amount value={amount} currency={currency} /> is added to Deposit {title} in {currency} {getCurrencySymbol(currency)}</>)
      closeDialog();
    })
  });

  const closeDialog = () => {
    onClose()
    formik.resetForm();
  };

  const closeDeposit = () => api.closeDeposit(depositId, currency).then(data => {
    onUserReceived(data);
    showSnackbarMessage(
      <>
        Deposit {title} in {currency} {getCurrencySymbol(currency)} has been closed.
        <Amount value={value} currency={currency} /> is added to the current account.
      </>
    );
    closeDialog();
    resetDepositOptions();
  });

  return (
    <Dialog open={isOpen} onClose={closeDialog} maxWidth="md" fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          Deposit {title} in {currency} {getCurrencySymbol(currency)}
        </DialogTitle>
        <DialogContent>
          <Typography component="div" variant="body2" color="textSecondary">
            <BasicDepositInfo {...deposit} currentWeek={currentWeek} />
          </Typography>
          <FormControl className={styles.formControl}>
            <TextField
              name="amount"
              label={`Replenish amount (${currency} ${getCurrencySymbol(currency)})`}
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.amount && !!formik.errors.amount}
              helperText={formik.touched.amount && formik.errors.amount}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">Cancel</Button>
          {closable && <Button onClick={closeDeposit} variant="contained" color="secondary">Close deposit</Button>}
          <Button type="submit" color="primary">Add to deposit</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default connect(
  ({ user: { accounts, currentWeek } }, { deposit }) => ({ accountBalance: accounts.find(a => a.currency === deposit.balance.currency).value, currentWeek }),
  { onUserReceived, showSnackbarMessage, resetDepositOptions }
)(DetailsPopup);