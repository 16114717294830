import 'flag-icon-css/css/flag-icon.min.css';
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  makeStyles, Button, Dialog, DialogActions, Grid, DialogTitle, DialogContent, DialogContentText,
  TextField, MenuItem, Typography
} from '@material-ui/core';
import api from 'utils/api';
import ReactEcharts from 'echarts-for-react';
import { useFormik } from 'formik';
import { showSnackbarMessage } from 'behavior';

const useStyles = makeStyles({
  root: {
    '& .MuiSelect-root .flag-icon': {
      display: 'none'
    }
  },
  dialogTitle: {
    '& h2': {
      fontSize: 25
    }
  },
  subTitle: {
    fontSize: 20,
    lineHeight: '32px',
  },
  feedbackTitle: {
    marginTop: '40px',
  },
  chart: {
    width: '100%',
    height: '400px !important'
  },
  countrySelect: {
    width: '100%',
    '& .MuiInput-formControl': {
      marginTop: 0
    }
  },
  option: {
    '& > .flag-icon': {
      marginRight: 10
    }
  }
});

const UserReportDialog = ({ open, onClose, userCountryId, countries, showSnackbarMessage }) => {
  const styles = useStyles();
  const [userReports, setUserReports] = useState();
  const [countryReports, setCountryReports] = useState();
  const [selectedCountryId, setCountryId] = useState(userCountryId);

  useEffect(() => {
    api.getReports().then(({ userReportCategories, countryReportCategories }) => {
      setUserReports(userReportCategories);
      setCountryReports(countryReportCategories);
    });
  }, []);

  const handleCountryChange = useCallback(e => setCountryId(e.target.value), []);

  const formik = useFormik({
    initialValues: {
      feedback: '',
    },
    validate: values => {
      const errors = {};

      if (!values.feedback)
        errors.feedback = `Please put your feedback here`;

      return errors;
    },
    onSubmit: ({ feedback }) => {
      api.sendFeedback(feedback).then(() => {
        showSnackbarMessage('Thank you for your feedback!')
        closeDialog();
      })
    }
  });

  const closeDialog = () => {
    onClose();
    formik.resetForm();
  };

  return !userReports || !countryReports ? null : (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose} disableBackdropClick className={styles.root}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle className={styles.dialogTitle}>Your 60 weeks report is ready!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Thank you for participating in Personal Finance simulator development.<br />
            You are still able to proceed with implementing any ideas you want to try.<br />
            There is just a report for your first 60 weeks.<br />
            You can compare it with other game sessions for your country or select different country to compare.<br />
          </DialogContentText>
          <Grid container>
            <Grid item lg={6} sm={12}>
              <Typography variant="h5" component="h3" className={styles.subTitle}>You results</Typography>
              <ReactEcharts className={styles.chart} option={createOption(userReports)} />
            </Grid>
            <Grid item lg={6} sm={12}>
              <Grid container>
                <Grid item lg={7} sm={6}>
                  <Typography variant="h5" component="h3" className={styles.subTitle}>Country results</Typography>
                </Grid>
                <Grid item lg={5} sm={6}>
                  <TextField
                    select
                    label=" "
                    value={selectedCountryId}
                    onChange={handleCountryChange}
                    className={styles.countrySelect}
                  >
                    {countryReports.map(r => (
                      <MenuItem key={r.countryId} value={r.countryId} className={styles.option}>
                        {(() => {
                          const country = countries.find(c => c.code === r.countryId);
                          return (
                            <>
                              <span className={`flag-icon flag-icon-${country.code.toLowerCase()}`} />
                              {country.code} - {country.name}
                            </>
                          );
                        })()}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <ReactEcharts className={styles.chart} option={createOption(countryReports.find(c => c.countryId === selectedCountryId).reportCategories)} />
            </Grid>
            <Typography variant="h5" component="h3" className={`${styles.subTitle} ${styles.feedbackTitle}`}>We need your feedback</Typography>

            <DialogContentText>
            </DialogContentText>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                name="feedback"
                label="Any suggestions or ideas?"
                value={formik.values.feedback}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.feedback && !!formik.errors.feedback}
                helperText={formik.touched.feedback && formik.errors.feedback}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={onClose}>Close</Button>
          <Button variant="contained" type="submit" color="primary">Send feedback</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(({ user: { country }, countries }) => ({ userCountryId: country, countries }), { showSnackbarMessage })(UserReportDialog);

function createOption(items) {
  return {
    tooltip: {
      trigger: 'axis',
      formatter: 'Week {b}<br />{a0}: {c0} $<br />{a1}: {c1} $<br />{a2}: {c2} $'
    },
    legend: {
      bottom: 0,
      data: items.map(i => i.category)
    },
    grid: {
      top: 20,
      left: '3%',
      right: '3%',
      bottom: 40,
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: items[0].data.map(i => i.week)
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value} $'
      }
    },
    series: items.map(i => ({
      name: i.category,
      type: 'line',
      data: i.data.map(d => d.amount)
    }))
  };
}