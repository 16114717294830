import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import api from 'utils/api';
import { CardContent, Table, TableRow, TableBody, TableCell, Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CollapsibleCard from '../CollapsibleCard';
import NonFinancialInvestmentDialog from './NonFinancialInvestmentDialog';

const NonFinancialInvestmentsCard = ({ nonFinancialInvestments, currentWeek }) => {
  const [items, setItems] = useState();
  const [availableItems, setAvailableItems] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    (async () => {
      setItems(await api.getNonFinancialInvestments());
    })();
  }, []);

  useEffect(() => {
    items && setAvailableItems(items.filter(({ id, availableInWeeks: { from, to } }) => !nonFinancialInvestments.map(({ id }) => id).includes(id) && from <= currentWeek && currentWeek <= to));
  }, [items, currentWeek, nonFinancialInvestments]);

  const handleItemSelect = useCallback(item => {
    setSelectedItem(item);
    setDialogOpen(true);
  }, []);

  if (!availableItems)
    return null;

  return (
    <CollapsibleCard title="Non financial investments">
      <CardContent>
        {!availableItems.length
          ? (
            <Alert severity="info">
              <AlertTitle>No options available for now.</AlertTitle>
            </Alert>
          )
          : (
            <Table>
              <TableBody>
                {availableItems.map(item => (
                  <TableRow key={item.id}>
                    <TableCell>{item.title}</TableCell>
                    <TableCell align="right">
                      <Button variant="contained" color="primary" size="small" onClick={handleItemSelect.bind(null, item)}>View details</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
      </CardContent>
      {selectedItem && (
        <NonFinancialInvestmentDialog item={selectedItem} open={dialogOpen} onClose={setDialogOpen.bind(null, false)} />
      )}
    </CollapsibleCard>
  );
};

export default connect(
  ({ user: { nonFinancialInvestments, currentWeek } }) => ({ nonFinancialInvestments, currentWeek })
)(NonFinancialInvestmentsCard);