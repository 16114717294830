import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import api from 'utils/api';
import { makeStyles, CardContent, Grid, TextField, MenuItem } from '@material-ui/core';
import CollapsibleCard from './CollapsibleCard';
import ReactEcharts from 'echarts-for-react';
import getCurrencySymbol from 'currency-symbol-map';

const useStyles = makeStyles({
  root: {
    '& .MuiSelect-root .currency-flag': {
      display: 'none'
    }
  },
  currencySelect: {
    width: '100%',
    '& .MuiInput-formControl': {
      marginTop: 0
    }
  },
  option: {
    '& > .currency-flag': {
      marginRight: 10
    }
  }
});

const WeekUpdateCard = ({ accounts, defaultCurrency, currencies }) => {
  const styles = useStyles();
  const [data, setData] = useState([]);
  const [selectedId, setId] = useState(defaultCurrency);
  const selectedItem = useMemo(() => data.find(item => item.id === selectedId), [data, selectedId]);
  const selectedCurrency = useMemo(() => selectedItem && (currencies.find(c => c.code === selectedItem.id) || { code: 'USD' }), [currencies, selectedItem]);

  useEffect(() => {
    (async () => {
      setData(await api.getWeekInfo());
    })();
  }, [accounts]);

  return (
    <CollapsibleCard title="Weekly update" className={styles.root}>
      {data.length !== 0 && (
        <CardContent>
          {accounts.length > 1 && (
            <Grid item lg={6} sm={12} style={{ marginBottom: 10 }}>
              <TextField
                select
                label=" "
                value={selectedId}
                onChange={e => setId(e.target.value)}
                className={styles.currencySelect}
              >
                {data.map(r => (
                  <MenuItem key={r.id} value={r.id} className={styles.option}>
                    {(() => {
                      const currency = currencies.find(c => c.code === r.id);
                      return (
                        <>
                          <span className={`currency-flag currency-flag-${(currency && currency.code.toLowerCase()) || 'usd'}`} />
                          {(currency && currency.code) || 'Accounts total amount in USD'}
                        </>
                      );
                    })()}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <ReactEcharts option={createOption(selectedItem.categories, selectedCurrency.code)} notMerge />
        </CardContent>
      )}
    </CollapsibleCard>
  );
};

export default connect(({ user: { accounts, defaultCurrency }, currencies }) => ({ accounts, defaultCurrency, currencies }))(WeekUpdateCard);

function createOption(transactions, currencyCode) {
  const emphasisStyle = {
    itemStyle: {
      barBorderWidth: 1,
      shadowBlur: 10,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowColor: 'rgba(0,0,0,0.5)'
    }
  };

  return {
    tooltip: {
      trigger: 'item',
      formatter: `{a}: {c} ${getCurrencySymbol(currencyCode)}`
    },
    grid: {
      top: 20,
      left: '3%',
      right: '3%',
      bottom: 40,
      containLabel: true
    },
    xAxis: {
      data: ['Income', 'Output'],
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: `{value} ${getCurrencySymbol(currencyCode)}`
      }
    },
    series: [
      ...transactions.filter(i => i.amount >= 0).map(i => ({
        name: i.title,
        type: 'bar',
        stack: 'one',
        emphasis: emphasisStyle,
        data: [i.amount, 0]
      })),
      ...transactions.filter(i => i.amount < 0).map(i => ({
        name: i.title,
        type: 'bar',
        stack: 'one',
        emphasis: emphasisStyle,
        data: [0, i.amount]
      })),
    ]
  };
}